<template>
  <div class="hgPromoWrapper" v-if="ads.length > 0">
    <hg-link-handler
      class="item"
      v-for="(ad, index) in ads"
      :link="ad.link"
      :key="ad.id"
      @click.native="() => pushGtmData(ad, index)"
    >
      <HgPromotionbanner :key="ad.id" :ad="ad" @onClick="handleClick" />
    </hg-link-handler>
  </div>
</template>

<script>
import getPromotionArea from '@/apollo/queries/ads/getPromotionArea.gql';
export default {
  data: () => ({
    ads: []
  }),
  methods: {
    handleClick(ad) {
      window.open(ad.link, '_blank');
    },
    pushGtmData(deal, index) {
      let position = "total"

      if (this.ads.length > 1) {
        if (window.innerWidth > 768) {
          // Tablet / Desktop
          position = index == 0 ? "left" : "right"
        } else {
          // Mobile
          position = index == 0 ? "top" : "bottom"
        }
      }

      this.$gtm.push({
        event: 'gtmEvent',
        eventName: 'click_to_promotion',
        area: 'FRONTPAGE_BANNER',
        dealName: deal.title,
        uId: deal.id,
        position
      });
    },
    isAdVisible(ad) {
      const today = new Date();
      const endDate = this.$dateFns.parseISO(ad.visibilityEndDate);
      const startDate = this.$dateFns.parseISO(ad.visibilityStartDate);

      return (
        this.$dateFns.isBefore(today, endDate) &&
        this.$dateFns.isAfter(today, startDate)
      );
    }
  },
  apollo: {
    getPromotionArea: {
      query: getPromotionArea,
      variables() {
        return {
          area: 'FRONTPAGE_BANNER',
          mimetype: 'webp',
          width: 1000
        };
      },
      result({ data }) {
        if (!data.getPromotionArea) return;
        const tempAds = [];

        data.getPromotionArea.forEach((ad) => {
          if (this.isAdVisible(ad)) {
            tempAds.push({
              imageSrc: ad.image?.url ?? '',
              link: ad.buttonLink ?? '/',
              title: ad.headline ?? '',
              subtitle: ad.description ?? '',
              buttonText: ad.buttonText ?? 'Jetzt stöbern',
              id: ad.id ?? '',
              visibilityStartDate: ad.visibilityStartDate ?? '',
              visibilityEndDate: ad.visibilityEndDate ?? '',
              position: ad.position ?? 0
            });
          }
        });

        // Sort by position
        tempAds.sort((a, b) => a.position - b.position);

        // Make sure we only have 2 ads
        this.ads = tempAds.slice(0, 2);
      }
    }
  }
};
</script>

<style scoped lang="postcss">
.hgPromoWrapper {
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  height: 22.5rem;
  padding: 0 1.5rem;
  margin: 0 auto;
  max-width: 1400px;

  a:first-child {
    width: 140%;
  }

  a:nth-child(2) {
    width: 100%;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    height: fit-content;
    margin: 0 1rem;
    padding: 0;

    a {
      height: 20rem;
    }

    a:first-child {
      width: 100%;
    }
  
    a:nth-child(2) {
      width: 100%;
    }
  }

  @media(max-width: 1015px) {
    a:first-child {
      width: 100%;
    }
  
    a:nth-child(2) {
      width: 100%;
    }
  }
}
</style>
